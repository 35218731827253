/* Spinner.css */

/* Container for the spinner */
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
}

/* Spinner border */
.spinner-border {
  width: 3rem;
  height: 3rem;
  border: 0.3em solid rgba(0, 0, 0, 0.1);
  border-top-color: #000;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

/* Spinner animation */
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

/* Accessibility support for visually hidden text */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
