ul.uzaPagination {
    background: #e5e5e5;
    padding: 3px;
    border-radius: 20px;
    border: 1px dashed #c5c5c5;
}

ul.uzaPagination li {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    font-size: 12px;
    border-radius: 509%;
    align-items: center;
    cursor: pointer;
}

ul.uzaPagination li.active {
    background: var(--theme-yellow);
    color: aliceblue;
}