/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body,
html {
  font-family: "Poppins", sans-serif !important;
  overflow-x: hidden;
  height: 100%;
}

html {
  overflow: unset !important;
}

body {
  background: #fff !important;
}

:root {
  --theme-color: #233448;
  --theme-yellow: #f3b04c;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  cursor: pointer !important;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

p {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000000;
}

.text-theme-primary {
  color: var(--theme-color);
}

.text-theme-secondary {
  color: var(--theme-yellow);
}

.bg-theme-primary {
  background: var(--theme-color);
}

.bg-theme-secondary {
  background: var(--theme-yellow);
}

.border-theme-primary {
  border-color: var(--theme-color);
}

.border-theme-secondary {
  border-color: var(--theme-yellow);
}

.w-fit {
  width: fit-content !important;
}

.btn-theme-primary,
.btn-theme-primary:active,
.btn-theme-primary:focus,
.btn-theme-primary:focus-visible {
  background: var(--theme-color) !important;
  border-color: var(--theme-color) !important;
}

.btn-theme-secondary,
.btn-theme-secondary:active,
.btn-theme-secondary:focus,
.btn-theme-secondary:focus-visible {
  background: var(--theme-yellow) !important;
  border-color: var(--theme-yellow) !important;
}

.btn:hover {
  opacity: 0.9;
}

.btn,
.btn:active,
.btn:hover,
.btn:focus,
.btn:focus-visible,
input,
input:active,
input:hover,
input:focus,
input:focus-visible {
  box-shadow: none !important;
}

.iconWithText .icn.position-absolute {
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}

.signupinput_phone .react-tel-input input {
  border: 0;
}

section.header-main {
  /* padding: 8px 0; */
  width: 100%;
  background-color: var(--theme-color);
  /* position: fixed;
  top: 0;
  z-index: 3; */
  transition: 0.3s ease-in-out;
  /* height: 90px; */
}

.logo-wrapper {
  width: 110px;
}

section.header-main.fixed-header .container {
  border: none;
  padding: 0;
}

.header-section-wrapper {
  position: fixed;
  z-index: 3;
  transition: 0.3s ease-in-out;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  top: 0;
}

.menu_strip.fixed-strip {
  position: fixed;
  top: 104px;
  z-index: 99;
  left: 0;
}

/* section.header-main.fixed-header {
  position: fixed;
  z-index: 3;
  transition: 0.3s ease-in-out;
  background: #000;
  backdrop-filter: blur(2px);
  top: 0;
} */

/* section.header-main.fixed-header a.navbar-brand {
  width: 210px;
} */

/* section.header-main.fixed-header ul.navbar-nav li a {
  color: #fff !important;
} */

section.header-main.fixed-header nav.navbar.navbar-expand-lg {
  padding: 0;
}

ul.social-list li {
  list-style-type: none;
}

ul.social-list {
  margin: 0;
  padding: 0 20px;
  display: flex;
  gap: 15px;
}

ul.social-list li a {
  padding: 0;
}

ul.navbar-nav li a {
  color: #000 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

ul.navbar-nav li a.active {
  font-weight: 900;
}

ul.navbar-nav li a:hover {
  color: var(--btn-theme);
  /* background: var(--btn-theme); */
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

ul.navbar-nav li a::before {
  content: "";
  height: 2px;
  width: 100%;
  border-radius: 20px;
  background: #000;
  transform: scaleX(0);
  position: absolute;
  bottom: -5px;
  left: 0;
  transition: 0.3s ease-in-out 0s;
}

ul.navbar-nav li a:hover::before {
  transform: scaleX(1);
  transition: 0.3s ease-in-out 0s;
}

section.header-main.fixed-header ul.navbar-nav li a:hover::before {
  background: var(--btn-theme);
}

ul.navbar-nav li a:hover,
ul.navbar-nav li a:focus {
  color: var(--btn-theme);
}

button.close-menu {
  display: none;
}

div.tab-frame input {
  display: none;
}

div.tab-frame label {
  display: block;
  float: left;
  padding: 5px 12px 12px 12px;
  margin-right: 22px;
  cursor: pointer;
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

div.tab-frame input:checked+label {
  cursor: pointer;
  position: relative;
  color: var(--theme-yellow);
  border-bottom: 2px solid var(--theme-yellow);
}

div.tab-frame .clearfix {
  border-bottom: 1px solid #eee;
}

div.tab-frame div.tab {
  display: none;
  padding: 5px 10px;
  clear: left;
}

div.tab-frame input:nth-of-type(1):checked~.tab:nth-of-type(1),
div.tab-frame input:nth-of-type(2):checked~.tab:nth-of-type(2),
div.tab-frame input:nth-of-type(3):checked~.tab:nth-of-type(3),
div.tab-frame input:nth-of-type(4):checked~.tab:nth-of-type(4),
div.tab-frame input:nth-of-type(5):checked~.tab:nth-of-type(5) {
  display: block;
}

form.get-in-form input {
  display: block;
  border-radius: 12px;
}

ul.navbar-nav li {
  padding: 0 10px;
}

.wallet-section .dropdown-menu.show {
  display: block;
  background: #e3e2df;
  border-radius: 0px 0px 10px 10px;
  min-width: 148px;
  border: 1px solid #000;
  transform: translate(0px, 50px) !important;
  left: -120px !important;
}

.wallet-section .dropdown-menu.show::before {
  content: "";
  position: absolute;
  top: -9px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #e3e2df;
  right: 9px;
  filter: drop-shadow(0px -1px #000);
}

.wallet-section .dropdown-menu.show button a {
  color: #000;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  text-decoration: none;
  display: block;
}

.wallet-section button.dropdown-item {
  text-align: center;
  padding: 0;
}

.wallet-section .dropdown-menu.show .dropdown-divider {
  border-top: 1px solid #000;
  margin: 0px 0;
}

.wallet-section button.dropdown-item:hover {
  background: var(--btn-theme);
  color: #fff;
}

.footerlogo_here {
  text-align: left;
}

.footerlogo_here img {
  width: 80px;
  height: 80px;
}

ul.footer-links li {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  text-transform: uppercase;
  color: #fff;
  list-style-type: none;
}

ul.footer-links {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin: 0;
  padding: 0;
  margin-top: 33px !important;
}

.form-group {
  display: flex;
  align-items: center;
}

.footerform {
  text-align: left;
  width: 70%;
  margin: 0px auto;
}

.footerform form label {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #e29831;
}

.footerform form input {
  background: #ffffff;
  border: 2px solid #f1f1f1;
  border-radius: 45.595px;
  padding: 15px 18px;
  height: 60px;
}

.footerform form input::placeholder {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #959595;
}

.footerform form input:focus {
  box-shadow: unset !important;
}

button.Signup_Now {
  position: absolute;
  right: 3px;
  border-radius: 45.595px;
  background-color: var(--btn-theme);
  border: none;
  height: 54px;
  width: 128px;
}

.social-icon-wrapper {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f3b04c;
}

.footer-copyright p {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.footer-copyright p span {
  color: var(--text-color);
}

section.footer-main {
  padding: 50px 0;
  background: #233448cc;
  /* border-top: 1px solid var(--theme-yellow); */
}

/* .footer-main input{
  border: 1px solid var(--theme-yellow) !important;
} */

.footer-copyright {
  background: #202020;
  padding: 22px 0;
  margin-top: 30px;
}

section.signup_main {
  height: 100%;
  min-height: 100vh;
  background-color: var(--theme-bg);
}

.subscriber {
  width: 60% !important;
  margin: 0px auto 60px;
}

.subscriber input {
  width: 100%;
  height: 43px;
  border-radius: 16px;
  border: none;
  padding: 15px;
}

.subscriber input:focus {
  box-shadow: 0 0 0 0.25rem rgb(191 142 16 / 46%);
  outline: none;
}

button.subscribe_btn {
  border: none;
  font-weight: 600;
  font-size: 18px;
  border-radius: 16px;
  height: 43px;
  background: var(--theme-yellow);
}

/* sign up pages */

/* sign up pages */

/* FAQ */

/* sign up css */

.sign_up_inner {
  width: 100%;
  padding: 40px 70px 40px;
  text-align: left;
}

h3.Welcome {
  color: #241f20;
  font-family: "Poppins", sans-serif !important;
  font-size: 3.5vw;
  font-style: normal;
  font-weight: 900;
  line-height: 70px;
  margin: 0;
}

h2.Signup_head {
  color: var(--theme-text);
  font-family: "Poppins", sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 150% */
  margin-bottom: 0px;
}

button.eye_btn {
  background: transparent;
  border: none;
  padding: 0;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

button.eye_btn:hover,
button.eye_btn.active {
  background: transparent !important;
}

.check_box {
  text-align: left;
}

.check_box input {
  padding: 0px !important;
  height: 20px;
  width: 20px;
}

.check_box label {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

a.forgot_password {
  color: #241f20 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
}

.left_sign_img img {
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
}

.form_wraping {
  margin-top: 80px;
}

form.signup_form input::placeholder {
  color: #727272;
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 200% */
}

/* create nft css */

.profile_img_card {
  padding: 40px 14px 10px;
}

.imgeedit {
  width: 170px;
  height: 167px;
  margin: 0px auto;
  border-radius: 16px;
}

.imgeedit img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

a.edit_icon {
  position: absolute;
  z-index: 2;
  height: 25px;
  width: 25px;
  right: -3px;
  bottom: -4px;
}

.profile_Content h3 {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #e29831;
}

.profile_Content p {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin: 0;
  opacity: 0.5;
}

ul.dash_nav {
  margin: 0;
  padding: 20px 0px;
  height: 600px;
  overflow-y: scroll;
}

ul.dash_nav::-webkit-scrollbar {
  width: 0px;
}

ul.dash_nav li {
  list-style-type: none;
  position: relative;
}

/* ul.dash_nav li:not(:last-child) {
  margin-bottom: 6px;
} */
ul.dash_nav li a {
  display: flex;
  color: #fff;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  gap: 14px;
  align-items: center;
  padding: 11px 16px;
  border-radius: 30px 0px 0px 30px;
  position: relative;
}

ul.dash_nav li a::before {
  content: "";
  height: 1px;
  background: #fff;
  position: absolute;
  bottom: 0;
  width: 80%;
  right: 0;
}

.sub-menu ul li a::before {
  width: 94%;
}

ul.dash_nav li a:hover,
ul.dash_nav li a.active {
  color: #006aff;
  font-weight: 800;
}

ul.dash_nav li a:hover svg g path,
ul.dash_nav li a:hover svg path,
ul.dash_nav li a.active svg path,
ul.dash_nav li a.active svg g path {
  fill: #006aff;
}

.menuarrow_left {
  position: absolute;
  right: 36px;
}

.erro404page {
  width: 30%;
  text-align: center;
  margin: 0px auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.erro404page button.big-add-token {
  width: 100%;
  margin-left: 0 !important;
  border-radius: 10px;
  border: 1px solid var(--theme-yellow);
  background: var(--theme-yellow);
  color: #fff;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  padding: 15px;
}

.erro404page button.big-add-token p {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

section.errorpage {
  background-color: var(--theme-color);
  height: 100vh;
  width: 100%;
}

.managetable_section table.table thead tr th:first-child {
  text-align: left;
}

.managetable_section table.table tbody tr td:nth-child(2) {
  text-align: left;
}

.pagination_list p {
  color: #232638;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

ul.pagination {
  margin: 0;
  gap: 14px;
}

ul.pagination li a {
  border-radius: 6px;
  color: #505470;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

ul.pagination li a.active {
  background-color: var(--btn-theme);
  border-color: var(--btn-theme);
}

ul.pagination li a:hover {
  background-color: var(--btn-theme);
  color: #fff;
}

a.navbar-brand {
  width: auto;
  padding: 0;
  margin: 0;
}

.search_card_column {
  width: 80%;
  display: flex;
  align-items: center;
  gap: 30px;
}

.user_card {
  display: flex;
  /* align-items: center; */
  gap: 10px;
  position: relative;
  z-index: 2;
}

.search_with_dropdonw {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}

.dropdown_all_cat {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 15px 0px 0px 15px;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 30px;
  gap: 20px;
}

.search_bar input {
  width: 100%;
  min-width: 418px;
  height: 46px;
  padding: 10px;
  background: transparent !important;
  color: #fff;
  border: none;
  font-size: 16px;
}

.search_bar input:focus {
  outline: none;
  color: #fff;
}

.search_bar input::placeholder {
  color: #fff;
  opacity: 0.5;
  font-weight: 200;
}

.card_content h5 {
  font-size: 14px;
  color: #fff;
  margin: 0;
}

.card_content {
  width: 90px;
  text-align: left;
}

.dividerline_verticle {
  /* height: 40px; */
  background: #fff;
  width: 1px;
  border-radius: 10px;
}

.dividerline_search {
  height: 30px;
  background: #fff;
  width: 1px;
  border-radius: 10px;
}

.card_user .dropdown button.btn.btn-secondary {
  background: transparent;
  border: none;
}

.dropdown_all_cat button.btn.btn-secondary {
  color: #fff;
  font-weight: 600;
  gap: 10px;
  background: transparent;
  padding: 0;
  border: none;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
}

.dropdown_all_cat .dropdown-menu.show,
.card_user .dropdown-menu.show {
  background-color: rgba(255, 255, 255, 1);
  padding: 10px 0px;
  margin: 0;
  width: 100%;
  min-width: 230px;
  scrollbar-width: none;
  border: none;
  box-shadow: 0px 5px 12px #0000004d;
  /* height: 100%; */
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.dropdown_all_cat .dropdown-menu.show,
.card_user .dropdown-menu.show::-webkit-scrollbar {
  width: 5px;
}

/* .dropdown_all_cat .dropdown-menu.show,
.card_user .dropdown-menu.show::-webkit-scrollbar-track{
  background-color: #eee;
} */

.category-list-menu button {
  width: 100% !important;
  text-wrap: nowrap !important;
  background: white !important;
  color: black !important;
  border: none !important;
  border-bottom: 1px solid #ddd !important;
  border-radius: 0 !important;
}

button.search_button {
  border: none;
  height: 46px;
  width: 60px;
  border-radius: 0 15px 15px 0;
  background: var(--theme-yellow);
}

.dropdown_all_cat .dropdown-menu.show a,
.card_user .dropdown-menu.show a {
  color: #000;
  text-decoration: none;
  font-size: 15px;
  display: block;
  padding: 5px 10px;
}

.dropdown_all_cat .dropdown-menu.show a:hover,
.card_user .dropdown-menu.show a:hover {
  background: var(--theme-yellow);
  color: #fff;
}

/* menu home */
.menu_strip {
  background: #fff;
  padding: 4px 20px;
}

.menu_strip ul.p-menubar-root-list {
  margin: 0;
}

.menu_strip ul.p-menubar-root-list li.p-menuitem {
  margin-right: 30px;
}

.menu_strip ul.p-menubar-root-list li a {
  color: #000000a3;
  font-size: 15px;
  text-decoration: none;
}

ul.homeMenu_list li {
  margin-right: 15px;
  list-style-type: none;
}

ul.homeMenu_list li a {
  color: #000;
  text-decoration: none;
  font-size: 15px;
  display: block;
  padding: 5px 10px;
  position: relative;
}

ul.homeMenu_list li a::before {
  content: "";
  width: 100%;
  height: 3px;
  background: var(--theme-yellow);
  position: absolute;
  bottom: -3px;
  left: 0;
  display: none;
}

ul.homeMenu_list li a:hover::before,
ul.homeMenu_list li a.active::before {
  display: block;
}

.home_strip {
  padding-inline: 68px;
}

.menu_box_links li a {
  color: #000;
  text-decoration: none;
  font-size: 15px;
  display: block;
}

.menu_box_links li a:hover {
  color: var(--theme-yellow);
}

.mega_menu {
  position: absolute;
  width: 100%;
  left: 0;
  background: #fff;
  padding: 30px 60px;
  display: none;
  z-index: 9999;
  box-shadow: 0px 5px 8px #0000004f;
}

/* li.productmenu:hover .mega_menu {
  display: block;
} */

.menu_box {
  border: 1px solid #cccc;
  border-radius: 10px;
  padding: 35px;
}

.mega_menu_categorie {
  position: absolute;
  width: 100%;
  height: 80%;
  left: 0;
  background: #fff;
  padding: 0px 0px 0px 35px;
  display: none;
  z-index: 9999;
  box-shadow: 0px 5px 8px #0000004f;
}

/* li.productmenu:hover .mega_menu_categorie{
  display: block;
} */
.menu_inner_categories ul {
  padding: 0;
  width: 20%;
}

.menu_box p {
  margin: 0;
  font-size: 16px;
  color: #000;
}

.frequently_search {
  display: flex;
}

.frequently_search h6 {
  color: #fff;
  font-weight: 500;
  margin: 0;
  width: 160px;
  font-size: 14px;
}

.frequently_search ul {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
}

.frequently_search ul li {
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 100px;
  border: 2px solid #fff;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  list-style-type: none;
  padding: 5px 8px;
}

.menu_inner_categories ul {
  height: 100%;
  background: #fff;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 30px;
}

.mega_menu_categorie ul li {
  margin-right: 0 !important;
  position: relative;
}

.mega_menu_categorie ul li::before {
  content: "";
  height: 1px;
  width: 95%;
  margin: 0px auto;
  position: absolute;
  background-color: #ccc;
  left: -40px;
  right: 0;
}

.mega_menu_categorie ul li a {
  padding: 14px 20px;
}

.mega_menu_categorie ul li.active,
.mega_menu_categorie ul li a:hover {
  background-color: #f3b04c38;
}

.mega_menu_categorie li a:hover::before,
.mega_menu_categorie li a.active::before {
  content: unset !important;
}

.mega_menu_categorie ul li div {
  display: flex;
  padding: 11px 0px;
}

ul.submenu li div {
  padding: 11px 20px;
}

.wrapList {
  background: #f5f5f5;
  padding: 50px 20px;
}

.card_comnon-product {
  background: #fff;
  border-radius: 8px;
  padding: 8px;
}

section.products_card .row {
  row-gap: 25px;
}

.card_content_list {
  padding: 10px;
}

.upper_head h5 {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}

.upper_head p {
  font-size: 14px;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.products_ist p {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}

button.buy_now_btn {
  background-color: var(--theme-color);
  color: #fff;
  border: none;
  border-radius: 100px;
  width: 130px;
}

button.buy_now_btn:hover {
  background-color: var(--theme-yellow);
}

h5.fillter_head {
  font-size: 18px;
  font-weight: 500;
}

.fillter_accordiion {
  background: #fff;
  height: 100%;
  min-height: 100vh;
}

.fillter_accordiion .accordion-body {
  padding: 10px 40px;
}

.fillter_accordiion .accordion-body .form-check {
  padding: 0;
  text-align: left;
}

.fillter_accordiion .accordion-body .form-check label {
  font-size: 14px;
}

.fillter_accordiion .accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.fillter_accordiion .accordion-button:focus {
  box-shadow: unset !important;
}

/* menu home */

/* home bannre */
.home_banner {
  background-image: url(../images/bannerimg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.inner_bnaner_content {
  padding: 180px 40px 180px 0;
  width: 60%;
  position: relative;
  z-index: 1;
}

.inner_bnaner_content h1 {
  font-size: 4.5vw;
  font-weight: 600;
  color: #fff;
  letter-spacing: 3px;
}

.inner_bnaner_content p {
  color: var(--theme-yellow);
  font-size: 29px;
  font-weight: 400;
  line-height: 47px;
  width: 80%;
  margin: 27px 0;
}

.inner_bnaner_content input {
  background: #fff;
  border-radius: 100px;
  height: 55px;
  border: none;
}

.inner_bnaner_content input:focus {
  box-shadow: 0 0 0 0.25rem rgb(191 142 16 / 46%);
}

button.banner_search {
  border-radius: 100px;
  border: none;
  font-size: 26px;
  font-weight: 600;
  position: absolute;
  right: 6px;
  bottom: 5px;
  height: 45px;
  background: var(--theme-yellow);
  padding-inline: 26px;
  line-height: 26px;
}

button.banner_search:hover {
  background: var(--theme-color);
}

.banner_overlay {
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, var(--theme-color) 30%, transparent 150%);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.footer_column li {
  width: 17%;
  list-style-type: none;
}

.footer_column .subscription-wrapper {
  width: 25%;
  list-style-type: none;
}

.footer_subscription-input {
  input {
    padding: 8px 14px !important;
    border-radius: 16px;
    border: 0px;
  }
}

.subscriber h4 {
  color: var(--theme-yellow);
  margin-bottom: 15px;
}

.footer_column ul li h5 {
  font-size: 18px;
  font-weight: 600;
  color: var(--theme-yellow);
}

.footer_column ul li p {
  margin: 0;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: #fff;
}

.footer_column ul li a {
  margin: 0;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
}

.yellow_card {
  background: var(--theme-yellow);
  padding: 20px 0;
}

.common_card {
  background: var(--theme-color);
  border-radius: 14px;
  padding: 13px 30px;
  height: 100%;
  width: 95%;
  margin: 0px auto;
}

.common_card h4 {
  color: #fff;
  font-weight: 600;
  font-size: 24px;
}

.common_card p {
  color: #fff;
  line-height: 24px;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

.cardicon {
  border-radius: 100px;
  height: 50px;
  width: 50px;
  background: var(--theme-yellow);
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

h3.common_head {
  font-weight: 500;
  color: #000;
}

.discover_inner {
  background: #f4f4f4;
  border-radius: 14px;
  padding: 20px;
}

.card_top_head h4 {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
}

.card_top_head a {
  color: #827f7f;
  font-size: 14px;
}

.card_white {
  background: #fff;
  padding: 20px;
  border-radius: 13px;
}

.most_popular h6 {
  font-weight: 500;
  font-size: 15px;
  margin: 0;
}

.most_popular p {
  color: #827f7f;
  font-size: 13px;
  margin: 0;
}

.cardImg {
  background: #f4f4f4;
  padding: 20px;
  border-radius: 14px;
}

.car_bag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
}

.car_bag img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

span.Popular_bagde {
  background: #fff;
  padding: 4px 15px;
  border-radius: 100px;
  font-size: 13px;
  position: absolute;
  top: 6px;
  left: 10px;
}

.most_popular_slider .slick-prev:before,
.most_popular_slider .slick-next:before {
  content: unset !important;
}

.most_popular_slider .slick-prev {
  left: 5px;
  z-index: 2;
}

.most_popular_slider .slick-next {
  right: 10px;
}

.most_popular_slider .slick-prev,
.most_popular_slider .slick-next {
  background: #fff;
  height: 30px;
  width: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  box-shadow: 0 3px 6px #0003;
  top: 57%;
}

.new_arrival_img {
  width: 48%;
  border-radius: 12px;
  overflow: hidden;
  height: 148px;
  transition: 0.1s ease-in-out;
}

.new_arrival_img:hover img {
  transform: scale(1.1);
  transition: 0.1s ease-in-out;
}

.new_arrival_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lowest_img {
  height: 90px;
  width: 90px;
  background: #f4f4f4;
  border-radius: 10px;
}

.lowest_price p {
  margin: 0;
  font-size: 14px;
  color: #000;
}

.deals_img span {
  position: absolute;
  top: 10px;
  left: 10px;
}

.deals_img_wrap {
  border-radius: 14px;
  overflow: hidden;
  height: 160px;
}

.deals_img_wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.stream_line_ordering {
  background: #f4f4f4;
  border-radius: 14px;
}

.inner_strem {
  background: #fff;
  border-radius: 14px;
}

.inner_strem h3.common_head {
  width: 50%;
  font-size: 32px;
  line-height: 42px;
  font-weight: 500;
}

.welcome_side,
.auth_login_form {
  width: 50%;
  height: 100vh;
}

.welcome_side {
  background: var(--theme-color);
  background-image: url(../../assets/images/worldsdot.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 150%;
  overflow: hidden;
}

.overlay_set {
  height: 100%;
  width: 100%;
  background: #24344799;
  position: absolute;
  top: 0;
  left: 0;
}

.welcome_side {
  padding: 78px;
}

.welcome_side h2 {
  font-size: 3.5vw;
  color: #fff;
  font-weight: 700;
  margin: 50px 0;
}

.welcome_side p {
  font-size: 14px;
  text-align: left;
  color: #fff;
  margin: 0;
  width: 50%;
  line-height: 23px;
  font-weight: 300;
}

.create_account {
  margin: 15px 0;
}

.create_account p {
  margin: 0;
  font-size: 14px !important;
}

.create_account a {
  color: var(--theme-yellow);
  text-decoration: none;
  letter-spacing: 1px;
}

.logo_here {
  width: 170px;
  height: 130px;
}

.logo_here img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login_auth {
  background: #eff3f6;
  border-radius: 25px;
  width: 70%;
  text-align: left;
  padding: 30px 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.login_auth input {
  background: var(--theme-color) !important;
  border-radius: 15px;
  border: none;
  color: #fff !important;
  padding-left: 50px;
  height: 40px;
  font-size: 16px;
}

.login_auth input::placeholder {
  color: #fff;
  opacity: 0.5;
  font-size: 16px;
}

.login_auth input:focus {
  box-shadow: unset;
}

.login_auth h4 {
  color: var(--theme-color);
}

.react-tel-input .country-list {
  text-align: start;
}

.signupinput_phone .react-tel-input input {
  border-radius: 15px;
  width: 100%;
  height: 40px;
}

.signupinput_phone .react-tel-input .flag-dropdown {
  background-color: transparent;
  border: none;
}

.signupinput_phone .react-tel-input .selected-flag:hover,
.signupinput_phone .react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

.signupinput_phone .react-tel-input .flag-dropdown.open .selected-flag,
.signupinput_phone .react-tel-input .flag-dropdown.open {
  background: transparent;
}

.signupinput_phone .react-tel-input .selected-flag .arrow {
  border-top: 4px solid #fff;
}

.inner_welcome {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.auth_icon {
  position: absolute;
  left: 15px;
  bottom: 9px;
}

.password-eye {
  position: absolute;
  right: 15px;
  bottom: 9px;
  cursor: pointer;
}

.password-input {
  padding-right: 50px !important;
}

input.checkbox {
  height: auto;
  accent-color: var(--theme-yellow);
  border-radius: 100px;
}

.remember_me input {
  height: 20px;
  width: 20px;
  padding: 0;
  background-color: #fff !important;
  position: relative;
  border-radius: 100px !important;
  top: -2px;
}

.remember_me input::before {
  content: "✔";
  position: absolute;
  top: -1px;
  left: 4px;
  font-size: 15px;
  display: none;
}

.remember_me label,
.forgot_pasword a {
  font-size: 14px;
  font-weight: 600;
  color: var(--theme-color);
  text-decoration: none;
}

.remember_me .form-check-input:checked {
  background-color: var(--theme-yellow) !important;
}

.remember_me .form-check-input:checked::before {
  display: block;
}

button.auth_btn {
  background-color: var(--theme-yellow);
  border: none;
  border-radius: 8px;
  font-weight: 600;
  padding: 3px 24px;
}

.stremline_left ul li {
  list-style-type: none;
}

.inner_strem {
  padding-inline: 70px;
}

.strem_iconshow {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  position: absolute;
  left: -65px;
  background: #fff;
}

.stremline_left ul li:not(:last-child) {
  margin-bottom: 50px;
}

.strem_line_content h4,
.strem_line_content p {
  margin: 0;
}

.strem_line_content p {
  font-size: 15px;
  width: 80%;
}

.strem_line_content h4 {
  font-size: 20px;
  font-weight: 500;
  color: #000000a1;
}

.active_one h4 {
  color: #974d00;
  font-size: 24px;
}

.stremline_left {
  padding-left: 50px;
}

.stremline_left::before {
  content: "";
  height: 90%;
  width: 1px;
  background: #ccc;
  position: absolute;
  left: 37px;
  top: 32px;
}

.stremline_left ul li:first-child .strem_iconshow {
  background: var(--theme-yellow);
}

.stremline_left ul li:first-child .strem_iconshow svg path {
  fill: #ffff;
}

section.congrats_page {
  padding: 20px 100px;
  background-color: #f5f5f5;
}

.congrats_img img {
  width: 70%;
  height: auto;
  object-fit: cover;
}

.congrats_img {
  background: #eff3f6;
  border-radius: 30px;
  padding: 50px 0;
}

.consgratulation_Content {
  padding: 60px 90px;
}

.consgratulation_Content h4 {
  font-size: 28px;
  font-weight: 600;
  color: #243447;
}

.consgratulation_Content p {
  font-size: 20px;
  padding: 15px 30px;
  color: var(--theme-yellow);
}

.consgratulation_Content h3 {
  font-size: 23px;
  color: #243447;
}

/* home bannre */

/* products css */
.products_banner {
  background: url(../images/productsbanner.jpg) no-repeat center center;
  background-size: cover;
  padding: 40px 0 90px;
}

.products_banner h2 {
  font-weight: 600;
  color: var(--theme-yellow);
}

.products_banner p {
  margin: 0;
  color: #fff;
  font-size: 16px;
}

.cate_img {
  width: 60px;
  height: 60px;
  border: 1px solid var(--theme-yellow);
  margin: 0px auto;
  border-radius: 100px;
  overflow: hidden;
}

.cate_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

section.top_categories,
section.guranted_products {
  padding: 20px;
  box-shadow: 0px 6px 12px #ccc;
  border-radius: 8px;
  background-color: #fff;
}

section.guranted_products {
  box-shadow: 0px 4px 12px #ccc !important;
}

section.product_banner .container {
  margin-top: -50px;
}

p.categorie_name {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  padding: 0 20px;
}

h4.black_heading_top {
  font-size: 18px;
  font-weight: 500;
}

.innner_top_categories .slick-prev:before,
.innner_top_categories .slick-next:before {
  content: unset;
}

.innner_top_categories .slick-prev {
  left: -15px;
  top: 45%;
}

.innner_top_categories .slick-next {
  right: -5px;
  top: 45%;
}

h4.cat_yeloow_head {
  font-size: 22px;
  font-weight: 500;
  color: var(--theme-yellow);
  margin: 0;
}

p.card_sub_head {
  margin: 0;
  font-size: 13px;
  color: #000;
  opacity: 0.6;
}

.guranted_box {
  flex-wrap: wrap;
}

.box_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--theme-yellow);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  background-color: #f5f5f5;
  /* /* height: 195px; */
  width: 195px;
  /* flex: 1 0 195px */
}

.card_item_Show {
  aspect-ratio: 1/1;
  width: 100%;
}

.card_item_Show img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.box_wrap img {
  width: 200px;
  height: 196px;
  object-fit: cover;
}

.box_wrap:hover img,
.Sourceapp_wrap:hover img {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

section.product_banner {
  background: #f1f2f6;
  padding-bottom: 110px;
}

.Sourceapp_box {
  flex-wrap: wrap;
}

.single_box {
  background: #fff;
  padding: 14px;
  border-radius: 12px;
  width: 202px;
}

.single_box_content p {
  margin: 10px 0 0;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Sourceapp_wrap img {
  border-radius: 14px;
}

.Sourceapp_wrap {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.Sourceapp_wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.single_box_content {
  height: 38px;
}

.verified_card {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  border-radius: 12px;
  padding: 70px 30px;
  margin: 10px;
  height: 214px;
}

.card_overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, #000000eb 40%, transparent);
}

.verifiedcard_content {
  position: absolute;
  top: 20px;
  left: 20px;
}

.blue_gradient {
  background: linear-gradient(90deg, #004885 40%, transparent);
  color: #fff;
  border-radius: 10px;
  padding: 6px 20px;
  width: 290px;
  text-align: left;
  font-size: 14px;
}

p.leading_text {
  margin-top: 8px;
  font-size: 14px;
}

.column_boxes {
  background: #fff;
  border-radius: 14px;
  width: 90px;
  height: 90px;
}

.innner_verify_categories .slick-prev,
.innner_verify_categories .slick-next {
  background-color: rgba(255, 255, 255, 0.5);
  height: 40px;
  width: 40px;
  border-radius: 100px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.innner_verify_categories .slick-prev:before,
.innner_verify_categories .slick-next:before {
  content: unset;
}

.innner_verify_categories .slick-prev {
  left: 15px;
  z-index: 9;
}

.innner_verify_categories .slick-next {
  right: 15px;
}

.innner_verify_categories .slick-prev:hover,
.innner_verify_categories .slick-next:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

section.single_view,
section.cart_view,
section.myaccount_sec,
section.checkout_view {
  /* padding: 50px 90px; */
  background-color: #f5f5f5;
}

.product_right_vcariant {
  border: 1px solid var(--theme-yellow);
  border-radius: 20px;
}

.product_right_vcariant ul li {
  list-style-type: none;
}

.same_content_single p {
  margin: 0;
  font-size: 15px;
  color: #000;
  opacity: 0.5;
}

.same_content_single h3 {
  font-size: 27px;
  font-weight: 600;
  margin: 0;
}

.product_preview p {
  display: flex;
  align-items: center;
  gap: 10px;
}

span.dot {
  height: 2px;
  width: 2px;
  background: #000;
}

.variants_title h6 {
  margin: 0;
  font-weight: 600;
}

.total_variation p {
  margin: 0;
  font-size: 14px;
}

.select_now .dropdown button.btn.btn-secondary {
  background: transparent;
  padding: 0;
  border: none;
  color: #000;
  text-decoration: underline;
  font-size: 13px;
  font-weight: 500;
}

.colors_col {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: #eee;
  overflow: hidden;
  border: 1px solid #fff;
  cursor: pointer;
}

.colors_col:hover {
  border: 1px solid var(--theme-yellow);
}

.colors_col img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ram_col {
  background: #eee;
  border-radius: 8px;
  min-height: 32px;
  min-width: 42px;
  padding: 0px 3px;
  border: 1px solid #eee;
}

.ram_col.active {
  /* border: 1px solid #000; */
  background-color: var(--theme-yellow);
}

.ram_col.active p {
  color: #fff;
}

.ram_col p {
  font-size: 12px;
  color: #000000c2;
}

.divone p {
  font-size: 13px;
  color: #000000c2;
}

.shipping p {
  margin: 0;
  font-size: 13px;
  line-height: 22px;
  color: #000000c2;
}

.button_three button {
  border-radius: 100px;
  background-color: transparent;
  border: none;
}

.button_three button:hover {
  background-color: var(--theme-yellow);
  background-color: var(--theme-yellow);
  border: 1px solid var(--theme-yellow);
  color: #fff;
}

.button_three button:hover svg path {
  fill: #fff;
}

button.Startbutton {
  background-color: var(--theme-yellow);
  border: 1px solid var(--theme-yellow);
  color: #fff;
}

button.addcart {
  color: #000;
  border: 1px solid #000;
  background-color: #fff;
}

button.Startbutton,
button.addcart {
  width: 41%;
}

button.chatbtn {
  border: 1px solid #000;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.cart_product {
  width: 70%;
}

.catpage_preview {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
}

.productd_wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.productd_wrap:not(:last-child) {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #eee;
}

.productimmg_side {
  display: flex;
  align-items: flex-end;
}

.product_img {
  width: 180px;
  height: 180px;
  border: 1px solid var(--theme-yellow);
  border-radius: 14px;
  overflow: hidden;
}

.product_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.price_quantity {
  display: flex;
  justify-content: space-between;
  gap: 53px;
  text-align: center;
}

.product_Content h3 {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}

.product_Content {
  width: 220px;
}

.product_Content p {
  margin: 0;
  color: #000;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.comon_setting p:last-child {
  margin: 0;
}

.comon_setting {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.counter_div button {
  background: transparent;
  border: 1px solid #ccc;
  padding: 0;
  height: 22px;
  width: 22px;
  color: var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 5px;
}

.counter_div button:hover {
  background-color: var(--theme-yellow);
  border: 1px solid var(--theme-yellow);
}

.div_output {
  width: 26px;
  height: 21px;
}

.div_output2 {
  width: 40px;
  /* height: 21px; */
  text-align: center;
}

.comon_setting p {
  font-size: 14px;
}

.calculating_shiiping {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

button.update_btn {
  background: var(--theme-color) !important;
  border: 1px solid var(--theme-color) !important;
  color: #fff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
}

.calculating_shiiping h5 {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color);
}

.calculating_shiiping select,
.calculating_shiiping input {
  background-color: #f1f1f1;
  height: 32px;
  padding: 0 10px;
  font-size: 13px;
  color: var(--theme-color);
  border-radius: 8px;
}

.calculating_shiiping input {
  background-color: #fff;
}

.calculating_shiiping input::placeholder {
  font-style: italic;
}

.calculating_shiiping select:focus,
.calculating_shiiping input:focus {
  box-shadow: unset;
}

.coupt_code h5,
.car_total h5 {
  font-size: 15px;
  font-weight: 600;
}

.coupt_code p {
  line-height: 18px;
  font-size: 12px;
}

.car_total {
  background-color: var(--theme-yellow);
  border-radius: 8px;
  padding: 15px;
}

.car_total ul li {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}

.car_total ul li p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
}

button.apply_btn {
  background: #fff;
  color: #000;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  border: none;
}

button.apply_btn:hover {
  background: var(--theme-color);
}

.cardone_wallet a {
  text-decoration: none;
}

.sinlge_product_slider .slick-dots {
  border: unset;
  left: -130px;
  top: 0px;
  width: 130px;
  background: transparent !important;
}

.sinlge_product_slider .slick-dots ul {
  padding: 0;
  height: 380px;
  overflow-y: scroll;
}

.sinlge_product_slider .slick-dots ul::-webkit-scrollbar {
  width: 0px;
}

.sinlge_product_slider .slick-dots ul li {
  width: 55px !important;
  height: 55px !important;
  margin: 8px 5px !important;
  border-radius: 8px;
  overflow: hidden;
  background-color: #eee;
}

li.slick-active {
  border: 1px solid var(--theme-yellow);
}

.thumbnail_img {
  width: 55px;
  height: 55px;
}

.thumbnail_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  z-index: 2;
}

.sinlge_product_slider .slick-prev:before,
.sinlge_product_slider .slick-next:before {
  content: unset;
}

.sinlge_product_slider .slick-next {
  right: 15px;
}

.sinlge_product_slider .slick-prev {
  left: 15px;
  z-index: 9;
}

.sinlge_product_slider .slick-prev,
.sinlge_product_slider .slick-next {
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 100px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px #ccc;
}

.sinlge_product_slider .slick-prev:hover,
.sinlge_product_slider .slick-next:hover {
  background-color: #fff;
  box-shadow: 0px 2px 10px #ccc;
}

.sinlge_product_slider {
  padding-left: 120px;
}

button.heart_btn {
  position: absolute;
  z-index: 2;
  right: 15px;
  padding: 0;
  background: transparent !important;
  border: none;
  top: 15px;
}

.blog_page,
.Contact_page {
  padding: 50px 90px;
}

.icon_set {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whitebackground {
  width: 58%;
  background: #f5f5f5;
  padding: 10px;
  margin: 0 20px;
  height: 100%;
  overflow-y: auto;
}

ul.submenu.submenu_third_big {
  overflow: auto;
  gap: 10px;
  padding: 16px;
  border-radius: 15px;
}

ul.submenu.submenu_third_big li {
  width: 115px;
  height: 140px;
}

ul.submenu.submenu_third_big li a {
  padding: 0;
}

ul.submenu.submenu_third_big li::before {
  content: unset;
}

ul.submenu.submenu_third_big li a:hover {
  background-color: transparent !important;
}

ul.submenu_third_big {
  display: flex;
  flex-wrap: wrap;
  min-height: unset !important;
  height: auto;
}

.third_ul_img {
  height: 80px;
  width: 80px;
  border-radius: 100px;
  margin: 0px auto;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  background: #eee;
  overflow: hidden;
}

.carttitle_head h4 {
  line-height: 38px;
}

.third_ul_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.search_card_column {
  /* width: 57%; */
  display: flex;
  align-items: center;
  gap: 30px;
  /* position: absolute;
  left: 0;
  right: 0; */
  /* margin: auto 50px auto auto; */
  position: relative;
  justify-content: center;
  /* top: -67px; */
}

::selection {
  background-color: var(--theme-yellow);
  color: #000;
}

/* products css */

/* Pagination */
ul.pagination-wrapper {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3px;
}

ul.pagination-wrapper a {
  text-decoration: none;
  color: black;
  padding: 0px 10px;
}

ul.pagination-wrapper li {
  background: #cfdae7;
  border-radius: 3px;
}

ul.pagination-wrapper li.selected {
  background: #233448;
  color: white;
}

/*  */

.cursor-pointer {
  cursor: pointer;
}

/* .send-otp-btn {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent !important;
  border-radius: 0px 13px 13px 0px !important;
  padding: 8px 7px !important;
  border: none !important;
  border-left: 1px solid white !important;
}
.send-otp-btn:disabled {
  background: transparent !important;
  border-radius: 0px 13px 13px 0px !important;
  padding: 8px 7px !important;
  border: none !important;
  border-left: 1px solid white !important;
  color: white !important;
}
.send-otp-email {
  padding-right: 110px;
} */

button.send-otp-btn {
  background: #de9d4d !important;
  border-radius: 0px 15px 15px 0px;
  border: 3px solid #ff9e4b;
  color: #fff !important;
  height: 36px;
  font-size: 16px;
  width: 100px;
  margin: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px !important;
}

button.send-otp-btn:disabled {
  background: #de9d4d !important;
  border: 3px solid #ff9e4b !important;
  color: #fff !important;
  opacity: 0.7;
}

.auth_login_form .inputWithBtn {
  background-color: var(--theme-color);
  border-radius: 15px;
  height: 40px;
}

.send-otp-email,
.send-otp-email input {
  border-radius: 15px 0 0 15px !important;
}

.modal {
  z-index: 9999999;
}

.retry-credentials {
  position: absolute !important;
  right: 10px;
  background: #978c8c;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}

.inputWithBtn .inputWrp,
.inputWithBtn .react-tel-input {
  width: calc(100% - 100px);
  height: 40px;
}

.login-phone .react-tel-input {
  width: 100%;
}

.signupinput_phone .react-tel-input input {
  border-right: 0;
  height: 40px;
}

.verify_input {
  background: var(--theme-color) !important;
  border-radius: 15px;
  border: none;
  color: #fff !important;
  padding-left: 50px;
  height: 40px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.verify_input p {
  color: #fff;
  margin: 0;
}

.card_user a {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.dialog-layout ul li {
  list-style-type: none;
}

.offcanvas.offcanvas-end.show {
  border: none;
}

.add_offcanva .offcanvas-body {
  padding: 0px;
}

.add_offcanva {
  z-index: 9999999 !important;
}

.subtotal_section ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subtotal_section {
  position: absolute;
  bottom: 0;
  background: #fff;
  width: 100%;
  padding: 20px;
  box-shadow: 10px 0 19px #ccc;
}

.dialog-layout {
  height: calc(100vh - 62px - 180px);
  overflow-y: auto;
  background: #f5f5f5;
}

.logout-button,
.logout-button:hover,
.logout-button:focus {
  color: black !important;
  width: 100%;
  text-align: start !important;
}

.auth_modal_content .login_auth {
  position: unset;
  transform: unset;
  width: 100%;
  padding: 20px 20px;
}

.auth_modal_content .login_auth input {
  display: block !important;
}

.auth_modal_content .welcome_side,
.auth_modal_content .auth_login_form {
  height: auto;
  width: 100%;
}

.for_loginmod .modal-content {
  border: none;
}

.close_icon {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  position: absolute;
  right: 10px;
}

.site-loader {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #23344854;
  color: var(--theme-color);
  z-index: 99999;
}

.site-loader .spinner-border {
  --bs-spinner-width: 4rem;
  --bs-spinner-height: 4rem;
}

.loading-content {
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000057;
  z-index: 99;
}

.no-record-found-content {
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-record-found-content p {
  color: var(--theme-color);
  font-size: xx-large;
}

button.manual_delete {
  width: 100%;
  padding: 13px 8px !important;
}

.stsearch-box input {
  border: none !important;
  font-family: "Poppins";
}

.stsearch-box input:focus-visible {
  outline: none !important;
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: var(--theme-yellow) !important;
  border-color: var(--theme-yellow) !important;
}

.cart_view .form-check-input:checked {
  background-color: var(--theme-yellow) !important;
  border-color: var(--theme-yellow) !important;
}

.cart_view .form-check-input:focus {
  box-shadow: none !important;
}

/* acoount css */
.profile_img {
  background: #eee;
  height: 120px;
  width: 120px;
  margin: 0px auto 10px;
  border-radius: 100px;
  overflow: hidden;
}

.sider_sidebar ul {
  padding: 0;
  list-style-type: none;
  text-align: left;
  margin: 0;
}

.sider_sidebar ul li:not(:last-child) {
  margin-top: 8px;
}

.sider_sidebar ul li a {
  padding: 12px 0px;
  width: 100%;
  display: block;
  text-decoration: none;
  color: #000;
}

.sider_sidebar ul li a.active {
  border-right: 1px solid var(--theme-yellow);
}

ul.my_order_list li,
.wrapping_list_order li {
  list-style-type: none;
}

.order_product_img {
  flex-shrink: 0;
  border-radius: 6px;
  width: 100px;
  height: 80px;
}

.order_product_img img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

ul.my_order_list li {
  border-bottom: 1px solid #ccc;
}

.order_product {
  width: 100%;
}

.order_product p {
  margin: 0;
  font-size: 16px;
}

p.product_name {
  font-weight: 500;
}

.order_product p:last-child {
  font-size: 14px;
}

.bottom_column p {
  margin: 0;
  font-size: 13px;
  margin: 0;
}

button.track_order {
  border-radius: 100px;
  border: none;
  background-color: var(--theme-yellow);
  color: #fff;
  font-size: 14px;
  height: 45px;
  font-weight: 600;
}

button.track_order:hover {
  background-color: var(--theme-color);
}

.wrap_box_address {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px;
  height: 100%;
}

.wrap_box_address button,
.wrap_box_address button:active,
.wrap_box_address button:focus,
.wrap_box_address button:focus-visible {
  background: none !important;
  border: none;
  padding: 0;
  box-shadow: none !important;
}

.wrap_box_address a,
.wrap_box_address span,
.wrap_box_address button,
.wrap_box_address button:hover,
.wrap_box_address button:active,
.wrap_box_address button:focus-visible {
  text-decoration: none;
  color: var(--theme-yellow) !important;
}

.wrap_box_address h5 {
  color: var(--theme-yellow);
  font-size: 16px;
}

.wrap_box_address p {
  margin: 0;
  font-size: 13px;
}

.account_details {
  background: var(--theme-yellow);
  /* padding: 50px 20px; */
  /* border-radius: 10px; */
}

.tab_form label {
  display: flex;
  font-size: 14px;
  margin-bottom: 8px;
}

.tab_form p {
  text-align: start;
}

.tab_form .row {
  row-gap: 22px;
}

.address_header a {
  color: #000;
  text-decoration: none;
  font-size: 14px;
}

.address_header h5 {
  margin: 0;
}

.adreesText p {
  margin: 0;
  font-size: 13px;
  line-height: 20px;
}

button.add_address {
  border: none;
  font-size: 15px;
  background: transparent !important;
  color: #000 !important;
  font-weight: 500;
  padding: 0;
}

.modal-title.h4 {
  font-size: 18px;
}

.address_select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.address_select ul li {
  color: #aaaaaa;
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: 100px;
}

.address_select ul li:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.address_select ul li input[type="radio"],
.address_select ul li input[type="checkbox"] {
  position: absolute;
  visibility: hidden;
}

.address_select ul li label {
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 1.35em;
  padding: 0 155px 25px 0;
  margin: 10px auto;
  height: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
}

.address_select ul li .check {
  display: block;
  position: absolute;
  background-color: #eee;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  top: 30px;
  right: 20px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.address_select ul li .check::before {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 25px;
  width: 25px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

.address_select input[type="radio"]:checked+label .check::before,
.address_select input[type="checkbox"]:checked+label .check::before {
  background: var(--theme-yellow);
  content: "✔";
  color: #fff;
}

.address_select input[type="radio"]:checked~label {
  color: #0dff92;
}

.addresstype label {
  margin: 0;
}

.addresstype input,
.default_house input {
  accent-color: green;
}

.divone .div_output2 {
  height: auto;
}

button:disabled {
  background-color: #ccc !important;
  border-color: #ccc !important;
}

.divone .div_output2 input {
  height: 25px;
}

/* acoount css */
.apply_coupon,
.apply_coupon:hover,
.apply_coupon:active,
.apply_coupon:focus {
  background: none !important;
  border: none !important;
  color: var(--theme-color) !important;
  text-align: end !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer;
}

.trash-button {
  background: white !important;
  border-color: black !important;
  padding: 0 !important;
  height: 30px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.cart-input,
.cart-input:active,
.cart-input:focus-visible,
.cart-input:focus {
  padding: 0 !important;
  margin: 0 !important;
  line-height: 1 !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
}

.add-to-cart-wrapper {
  display: flex;
}

.add-to-cart-wrapper button,
.add-to-cart-wrapper input {
  height: 22px !important;
  border-radius: 0px !important;
}

.add-to-cart-wrapper button:hover {
  border-color: #ccc !important;
}

.add-to-cart-wrapper input,
.add-to-cart-wrapper input:focus {
  border-right: none !important;
  border-left: none !important;
}

.add-to-cart-wrapper button:first-child {
  border-radius: 5px 0px 0px 5px !important;
}

.add-to-cart-wrapper button:last-child {
  border-radius: 0px 5px 5px 0px !important;
}

.custom-input {
  text-align: start;
  min-height: 20px;
  /* width: 450px; */
  border: 1px solid var(--theme-color);
  margin: auto;
  padding: 0px 10px;
}

.custom-input-title {
  font-size: 0.7rem;
  margin-bottom: 0;
}

.custom-input-title:after {
  content: "*";
  color: rgb(var(--bs-danger-rgb)) !important;
}

.custom-input-value {
  margin-bottom: 0;
}

.account_details button,
.account_details button:hover,
.account_details button:focus,
.account_details button:active {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  box-shadow: none;
}

.account_details button:hover {
  opacity: 0.9;
}

.required:after {
  content: "*";
  color: rgb(var(--bs-danger-rgb)) !important;
}

.min-quantity-label {
  margin-bottom: 0;
  text-align: start;
  margin-left: 40px;
  color: #6c6c6c;
  font-weight: 300;
}

img.empty-cart {
  width: 130px;
  margin-bottom: 30px;
}

.empty-cart-title {
  font-size: 1.5rem;
  font-weight: 600;
}

a.empty-cart-link {
  text-decoration: none;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
  padding: 10px 30px;
  border-radius: 15px;
}

.out-of-stock {
  background: var(--theme-color);
  color: white !important;
  padding: 0px 10px;
  border-radius: 50px;
  text-align: center;
  line-height: 16px;
}

.input-shadow,
.input-shadow:active,
.input-shadow:focus,
.input-shadow:hover {
  box-shadow: none !important;
}

.checkout_accordion .accordion-button,
.checkout_accordion .accordion-item {
  background-color: transparent !important;
}

.checkout_accordion .accordion-item {
  border: none;
  border-bottom: 1px solid #eee;
  border-radius: unset;
}

.checkout_accordion .accordion-button {
  font-weight: 500;
  font-size: 20px;
}

.checkout_accordion .accordion-button:focus {
  box-shadow: unset !important;
}

.checkout_accordion .accordion-button:not(.collapsed) {
  color: #f3b04c;
  box-shadow: unset;
}

.checkout_acccinner {
  width: 97%;
  margin-left: 30px;
}

.radio_set label {
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 1;
  box-sizing: border-box;
  display: flex;
  padding: 9px 10px 9px 60px;
  font-weight: 500;
  color: #191919;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.2s ease-in-out;
  border-radius: 5px;
  text-align: left;
  border: 1px solid transparent;
}

.radio_set input[type="radio"] {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  transition: linear 0.8s;
  height: 0;
  width: 0;
  -webkit-tap-highlight-color: transparent;
  left: 50px;
}

.radio_set input[type="radio"]:after {
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  top: -10.5px;
  left: -30px;
  border-radius: 20px;
  border: 2px solid #3a88f6;
  transition: linear 0.2s;
  cursor: pointer;
}

.radio_set input[type="radio"]:checked:after {
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  background: #3a88f6;
  transition: linear 0.2s;
  cursor: pointer;
}

.radio_set input[type="radio"]:checked:before {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background: #fff;
  left: -26px;
  top: -6.5px;
  z-index: 1;
  cursor: pointer;
}

.radio_set input[type="radio"]:checked+label {
  background-color: #f3b04c29;
  border: 1px solid #f3b04c;
}

button.add_new_address:hover,
button.add_new_address:active {
  color: #4388f3 !important;
}

button.add_new_address {
  display: flex;
  margin-top: 20px;
  background: transparent !important;
  border: none;
  color: #4388f3;
}

.use_thisadwrap {
  background: #eee;
  display: flex;
}

button.use_thisadd {
  background-color: #f3b04c;
  border-radius: 100px;
  color: #000;
  border: none;
  font-size: 15px;
  font-weight: 500;
}

.summary_list ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.summary_list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summary_list ul li p {
  margin: 0;
  font-size: 15px;
}

.summary_list ul li h4 {
  color: #f3b04c;
  font-weight: 500;
  font-size: 17px;
}

.use_thisadwrap a {
  text-decoration: none;
  font-size: 12px;
  color: #4388f3;
}

.use_thisaddress p {
  font-size: 12px;
  padding-inline: 20px;
  line-height: 20px;
  margin: 7px 0 0;
}

.mega_menu_categorie ul li div img {
  object-fit: cover;
  border-radius: 100px;
}

.disabled {
  pointer-events: none;
}

.checkout-coupon {
  border: 2px dashed #f3b04c;
  padding: 6px;
  width: fit-content;
  font-size: 1rem !important;
  margin-bottom: 13px !important;
}

.fs-base {
  font-size: 0.8rem !important;
  line-height: 1.2rem !important;
}

.fs-xs {
  font-size: 0.7rem !important;
  line-height: 1rem !important;
}

table.order-table tr th {
  padding: 10px;
  line-height: 30px;
  letter-spacing: 0.5px;
  border-top: none;
}

table.order-table tr td {
  background-color: transparent;
  padding: 10px;
  line-height: 30px;
  letter-spacing: 0.5px;
}

.btn-link {
  color: var(--theme-color) !important;
  text-decoration: none !important;
}

.btn-link:hover,
.btn-link:focus {
  background: none !important;
  text-decoration: underline !important;
}

.order-detail-tr {
  border-color: #000;
  text-align: end;
}

.order-detail-tr:not(:last-child) {
  border-color: #fff;
}

.gap-10 {
  gap: 10px;
}

.product_right_vcariant {
  height: 100%;
}

.product_description img {
  margin: 0px auto;
  display: block;
}

.product_attribute {
  margin-top: 50px !important;
}

.wrap_conatainer li.breadcrumb-item a {
  color: var(--theme-yellow);
  text-decoration: none;
}

.wrap_conatainer .card a {
  color: var(--theme-yellow);
}

.wrap_conatainer .table tbody tr td a {
  color: var(--theme-yellow);
}

.uza-product-description table {
  margin: auto !important;
}

.product_description img {
  width: 100% !important;
  max-width: 100%;
}

.uza-infinite-scroll .loading-content {
  min-height: 130px !important;
}

.Toastify {
  z-index: 99999999;
  position: relative;
}

/* Merchent register */
.merchent_signup .form-wrapper {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 40px 25px;
  border: 1px solid #ececec;
  border-radius: 20px;
  max-width: 900px;
  margin: auto;
  text-align: start;
}

.merchent_signup .form-wrapper .react-tel-input input {
  width: 100%;
  /* padding: .375rem .75rem; */
  border: none;
}

.merchent_signup .form-wrapper .react-tel-input .flag-dropdown {
  border: 1px solid #f5f5f5;
}

.merchent_signup button.auth_btn {
  padding: 10px 30px;
}

.uza-dropzone {
  margin-top: 10px;
  padding: 20px 10px;
  border-style: dotted;
  border-color: var(--theme-yellow);
}

.uza-dropzone p {
  margin: 0;
}

.uza-dropzone .file-preview {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: 10px;
  margin: 10px 0;
}

.uza-dropzone .file-preview .file-item {
  width: 120px;
}

.uza-dropzone .file-preview .file-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 50px;
}

/* Merchent register */

/* responsive start */

@media only screen and (min-width: 1360px),
(max-width: 1800px) {}

@media only screen and (max-width: 1360px) {}

@media only screen and (min-width: 1080px),
(max-width: 1276px) {
  .cart_view .product_img {
    width: 130px;
    height: 130px;
  }

  .cart_view h5 {
    font-size: 1rem;
  }

  .cart_view h3 {
    font-size: 14px;
  }

  .cart_view .price_quantity {
    gap: 30px;
  }

  .cart_view .product_Content {
    width: 200px;
  }
}

@media only screen and (max-width: 1169px) {
  .container {
    max-width: 95% !important;
  }

  button.mobile_toggle_btn {
    display: block;
    position: absolute;
    z-index: 2;
    top: 45px;
    left: 30px;
  }

  .search_card_column {
    width: 68%;
  }

  .search_bar input {
    width: 345px;
  }

  .frequently_search {
    display: block !important;
  }

  .frequently_search ul {
    margin-top: 10px;
  }

  .frequently_search ul li {
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 8px;
  }

  .inner_bnaner_content {
    width: 74%;
  }

  section.single_view,
  section.cart_view {
    padding: 50px 30px;
  }

  .same_content_single h3 {
    font-size: 20px;
  }

  button.Startbutton,
  button.addcart {
    width: 100%;
  }

  .button_three {
    flex-direction: column;
  }

  .product_preview p {
    gap: 7px;
    font-size: 14px;
  }

  .productd_wrap {
    display: block;
  }

  .comon_setting {
    gap: 0;
  }

  .price_quantity {
    margin-top: 40px;
  }

  .comon_setting p {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1099px) {
  .search_card_column {
    width: 59%;
  }

  .card_content {
    width: 72px;
  }

  .user_card {
    gap: 4px;
  }

  .search_bar input {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  section.myaccount_sec .sticky-top {
    position: unset !important;
  }

  ul.navbar-nav {
    position: absolute;
    top: 0;
    background: #000;
    left: -400px;
    width: 370px !important;
    z-index: 999;
    transition: 0.3s;
    height: 100vh;
    padding: 50px 0 0;
    box-shadow: 5px 5px 12px #11111157;
  }

  ul.navbar-nav li a {
    color: #fff !important;
  }

  .show ul.navbar-nav {
    left: 0;
    transition: 0.3s !important;
  }

  nav.navbar {
    width: 52%;
    position: inherit;
  }

  .navbar-collapse {
    transition: 3s;
  }

  button.close-menu {
    display: block;
    background: transparent;
    width: max-content;
    padding: 0;
    border: none;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  button.navbar-toggler {
    border: none;
    height: 32px;
    width: 32px;
    padding: 0;
    display: grid;
    justify-content: center;
    align-items: center;
    display: none;
  }

  button.navbar-toggler:focus {
    box-shadow: unset;
  }

  button.navbar-toggler .bar {
    height: 2px;
    width: 32px;
    background: #fff;
    position: relative;
    transition: 0.2s ease-in-out;
  }

  .bar:nth-child(1) {
    transform: rotate(48deg) translate(12px, 5px);
  }

  .bar:nth-child(3) {
    transform: rotate(-48deg) translate(10px, -2px);
  }

  button.navbar-toggler .bar:nth-child(2) {
    opacity: 0;
  }

  button.navbar-toggler.menu_click .bar:nth-child(2) {
    opacity: 1;
  }

  button.navbar-toggler.menu_click .bar:nth-child(1) {
    transform: rotate(0deg) translate(0px, 0px);
  }

  button.navbar-toggler.menu_click .bar:nth-child(3) {
    transform: rotate(0deg) translate(0px, 0px);
  }

  ul.navbar-nav li a.active {
    color: var(--btn-theme) !important;
  }
}

@media only screen and (max-width: 1023px) {
  .search_bar input {
    width: 285px;
  }

  .menu_inner_categories ul {
    width: 40%;
  }

  a.navbar-brand {
    width: 92px;
  }

  section.yellow_card .row,
  section.discover_section .row {
    row-gap: 30px;
  }

  .inner_strem h3.common_head {
    width: 100%;
  }

  .card_content {
    display: none;
  }

  .home_strip {
    padding-inline: 0;
  }

  .inner_bnaner_content {
    padding: 80px 20px;
    width: 100%;
  }

  .product_right_vcariant {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 979px) {}

@media only screen and (max-width: 799px) {
  .search_bar input {
    width: 225px;
  }

  .inner_strem {
    padding-inline: 40px;
  }

  .subscriber {
    width: 80% !important;
  }

  .footer_column ul {
    padding: 0;
    margin: 0;
  }

  .footer_column li {
    width: 25%;
  }

  .footer_column li:not(:last-child) {
    margin-bottom: 30px;
  }

  .strem_line_content h4 {
    font-size: 16px;
  }

  .strem_line_content p {
    font-size: 13px;
    width: 100%;
    line-height: 17px;
  }

  .search_card_column {
    width: 59%;
    /* top: -59px; */
  }

  .welcome_side p {
    width: 100%;
  }

  .login_auth {
    width: 88%;
    text-align: left;
    padding: 30px 20px;
  }

  .product_img {
    width: 120px;
    height: 90px;
  }

  .price_quantity {
    gap: 3px;
  }

  .product_right_vcariant {
    height: auto;
  }

  .product_description img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .search_card_column {
    width: 100%;
  }

  a.navbar-brand {
    margin: 0px auto;
  }

  section.header-main {
    padding: 8px 0;
  }

  .search_bar input {
    width: 325px;
  }

  .search_card_column {
    width: 100%;
    position: unset;
  }

  section.header-main {
    height: auto;
  }

  .search_with_dropdonw {
    justify-content: center;
  }

  a.navbar-brand {
    margin: 0px auto auto 0;
  }

  .mega_menu .row {
    row-gap: 22px;
  }

  .sinlge_product_slider .slick-dots ul li {
    width: 46px !important;
    height: 46px !important;
  }

  .product_right_vcariant {
    margin-top: 120px;
  }

  .consgratulation_Content {
    padding: 48px 0 0;
  }

  section.congrats_page {
    padding: 40px 30px;
  }

  .consgratulation_Content p {
    padding: 15px 0px;
  }

  .consgratulation_Content h4 {
    font-size: 22px;
  }

  .consgratulation_Content {
    padding: 33px 0 0;
  }

  .consgratulation_Content h3 {
    font-size: 18px;
  }

  section.congrats_page {
    padding: 40px 10px;
  }

  .calculating_shiiping {
    margin-top: 30px;
  }

  .cart_product {
    width: 100%;
  }

  .sinlge_product_slider .slick-dots ul {
    height: 70px;
    overflow: scroll;
    white-space: nowrap;
  }

  .product_right_vcariant {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 639px) {
  ul.homeMenu_list {
    overflow: scroll;
    white-space: nowrap;
  }

  .home_strip .container {
    margin: 0;
    padding: 0;
  }

  .menu_inner_categories ul {
    width: 74%;
  }

  .inner_bnaner_content p {
    font-size: 18px;
    line-height: 26px;
    width: 100%;
  }

  .inner_bnaner_content h1 {
    font-size: 26px;
  }

  .header .container-fluid {
    padding: 0;
  }

  .search_bar input {
    width: 185px;
  }

  a.navbar-brand {
    width: 76px;
  }

  .card_user .dropdown button.btn.btn-secondary {
    padding: 0;
  }

  .user_card {
    gap: 14px;
  }

  .dividerline_verticle {
    height: 30px;
  }

  .footer_column li {
    width: 48%;
  }

  .welcome_side,
  .auth_login_form {
    width: 100%;
    height: auto;
  }

  .inner_welcome,
  .login_auth {
    position: relative;
    transform: unset;
    width: 100%;
    left: unset;
    z-index: 2;
  }

  section.signin {
    flex-direction: column;
  }

  .login_auth {
    width: 90%;
    margin: 29px auto;
  }

  .welcome_side {
    padding: 28px;
  }

  .logo_here {
    width: 130px;
    height: 73px;
  }

  .welcome_side h2 {
    font-size: 6.5vw;
    margin: 30px 0;
  }

  .box_wrap {
    flex: 1 0 187px;
  }

  section.single_view,
  section.cart_view {
    padding: 50px 0px;
  }

  .sinlge_product_slider .slick-dots {
    width: unset;
    position: unset;
  }

  .sinlge_product_slider {
    padding-left: 0;
  }

  .product_right_vcariant {
    margin-top: 20px;
  }

  .product_preview p {
    line-height: 20px;
    display: block;
  }

  .subscriber {
    width: 100% !important;
  }

  section.single_view.my-5 {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 479px) {
  .new_arrival_img {
    height: 118px;
  }
}

@media only screen and (max-width: 390px) {
  .search_bar input {
    width: 135px;
  }

  .input_subsciber {
    flex-direction: column;
  }
}

@media only screen and (max-width: 386px) {
  .footer_column li {
    width: 100%;
  }
}

@media only screen and (max-width: 375px) {}

/* css */
.rightMenu {
  right: 0 !important;
  left: unset !important
}

.dropdown-item.active,
.dropdown-item:active {
  background: var(--theme-color) !important;
}

.auto-complete-input {
  display: block !important;
}

.auto-complete-input>div {
  left: 467.125px;
  top: 75.5px;
  width: 418px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
  background: rgba(255, 255, 255, 0.9);
  padding: 0px 0px !important;
  font-size: 90%;
  position: sticky;
  overflow: auto;
  max-height: 260px !important;
  z-index: 999;
}

.drop-zone-fileupload {
  border: 3px dashed var(--theme-yellow);
}

@media (max-width:991px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .search_bar input {
    min-width: 100%;
    width: 100%;
  }

  .mobile-view {
    flex-direction: column;
    align-items: flex-end !important;
    gap: 24px;
  }

  .user_card {
    position: absolute;
    top: 20px;
    right: 13px;
  }

  .menu_strip {
    margin-top: 21px;
    padding: 4px 8px;
  }

  .dropdown_all_cat {
    padding: 0px 0px 0px 10px;
  }

  button.search_button {
    width: 82px;
  }
}

@media (min-width:863px) and (max-width:991px) {
  .header {
    flex-direction: row;
    align-items: flex-start;
  }

  .mobile-view {
    flex-direction: row;
    align-items: flex-end !important;
    gap: 24px;
  }

  .user_card {
    position: static;
    top: 20px;
    right: 13px;
  }

  nav.navbar {
    width: 12%;
  }
}

.header-menu .dropdown-item.active,
.dropdown-item:active {
  background: #f3b04c !important;
}

.logout-button-header:hover {
  color: #fff !important;

}

.custom-dropdown .custom-dropdown-menu {
  background-color: white !important;
}

.custom-dropdown .dropdown-toggle {
  background-color: transparent !important;
  border: 0 !important;
  display: flex;
  align-items: center;
  gap: 5px;
}

.custom-dropdown .dropdown-item:hover a {
  color: #fff !important;
}

.product-chat-drawer img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.chat-message-drawer-wrapper {
  border-radius: 10px;
  height: calc(100vh - 203px);
  overflow-y: auto;
  margin: 0px;
  padding: 20px;
}

.has-file {
  height: calc(100vh - 308px);
}

.chat-message-footer-wrapper {
  margin: 0px 20px;
  padding: 10px;

}

.chat-message-footer-wrapper input {
  border: 0;
  background-color: #ddd;
  padding: 10px 20px;
  border-radius: 10px;
  width: 100%;
}

.chat-message-footer-wrapper form {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.chat-message-footer-wrapper button {
  border: 0px;
  padding: 10px 20px;
  border-radius: 10px;
}

.msg-left-wrapper {
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
}

.msg-right-wrapper {
  flex-direction: column;
  align-items: end;
  display: flex;
  width: 200px;
  gap: 10px;

  margin-bottom: 10px;
  width: 100%;
}

.chat-file img {
  width: 100px;
}

.message-text {
  width: fit-content;
  max-width: calc(100% - 100px);
  padding: 10px;
  background-color: #7c7c7c;
  color: #fff;
  border-radius: 10px;
}

.msg-right-wrapper .message-text {
  width: fit-content;
  max-width: calc(100% - 100px);
  padding: 10px;
  background-color: #212529;
  color: #fff;
  border-radius: 10px;
}



.upload-imgs-wrapper img {
  width: 100px;
  height: 70px;
  object-fit: cover;
}

.upload-icon-wrapper {
  top: 0px;
  width: 44px;
  height: 44px;
  background-color: #ddd;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-imgs-wrapper .file-thumbnail-upload {
  width: 100px;
}

.upload-btn-wrapper input {
  opacity: -1;
  width: 36px;
  z-index: 999;
  position: relative;
}

.file-thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  background-color: #f8f9fa;
  text-align: center;
}

.file-thumbnail-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  background-color: #f8f9fa;
  text-align: center;
}

.file-thumbnail i {
  font-size: 30px;
  color: #6c757d;
}

.file-thumbnail span {
  font-size: 12px;
  margin-top: 5px;
}