.MegaMenu {
  .inner {
    padding-left: 95px;
  }
  //   padding-left: 16%;
  // .submenu {
  //     display: none;
  // }

  // .firstLevelList:hover+.submenu {
  //     display: block;
  // }
}
