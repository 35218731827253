/* Blog.css */
.sidebar-card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.sidebar-item {
    cursor: pointer;
    transition: background-color 0.2s;
}

.sidebar-item:hover {
    background-color: #f8f9fa;
}

.blog-card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: transform 0.2s, box-shadow 0.2s;
}

.blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-card .card-img-top {
    border-radius: 10px 10px 0 0;
}

.card-title {
    font-size: 1.25rem;
    font-weight: 600;
}

.card-subtitle {
    font-size: 0.875rem;
}

.card-text {
    color: #6c757d;
}